@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

@font-face {
  font-family: "Posey";
  src: local("Posey Textured"), local("Posey-Textured"),
    url("./Assets/fonts/PoseyTexturedRegular.ttf") format("truetype"),
    url("./Assets/fonts/PoseyTexturedRegular.woff") format("woff"),
    url("./Assets/fonts/PoseyTexturedRegular.woff2") format("woff2");

  font-weight: normal;
  font-style: normal;
}

:root {
  --primary-color: #3c1f0c;
  --secondary-color: #fff;
  --text-color: #fff;
  --accent-color: #f4a641;
  --border-color: rgba(255, 255, 255, 0.12);
  --background-color: #0f101e;
  --error-color: rgb(230, 87, 87);
  --red-color: #ff0000;
  --green-color: #008000;
  --tg-primary-color: #23b7ff;
  --tg-secondary-color: #006fe0;
  --default-font: "Posey", sans-serif;
  --cui-blue: #0d6efd;
  --cui-indigo: #6610f2;
  --cui-purple: #6f42c1;
  --cui-pink: #d63384;
  --cui-red: #dc3545;
  --cui-orange: #fd7e14;
  --cui-yellow: #ffc107;
  --cui-green: #198754;
  --cui-teal: #20c997;
  --cui-cyan: #0dcaf0;
  --cui-black: #000015;
  --cui-white: #fff;
  --cui-gray: #8a93a2;
  --cui-gray-dark: #636f83;
  --cui-gray-100: #ebedef;
  --cui-gray-200: #d8dbe0;
  --cui-gray-300: #c4c9d0;
  --cui-gray-400: #b1b7c1;
  --cui-gray-500: #9da5b1;
  --cui-gray-600: #8a93a2;
  --cui-gray-700: #768192;
  --cui-gray-800: #636f83;
  --cui-gray-900: #4f5d73;
  --cui-primary: #321fdb;
  --cui-secondary: #9da5b1;
  --cui-success: #2eb85c;
  --cui-info: #39f;
  --cui-warning: #f9b115;
  --cui-danger: #e55353;
  --cui-light: #ebedef;
  --cui-dark: #4f5d73;
  --cui-primary-rgb: 50, 31, 219;
  --cui-secondary-rgb: 157, 165, 177;
  --cui-success-rgb: 46, 184, 92;
  --cui-info-rgb: 51, 153, 255;
  --cui-warning-rgb: 249, 177, 21;
  --cui-danger-rgb: 229, 83, 83;
  --cui-light-rgb: 235, 237, 239;
  --cui-dark-rgb: 79, 93, 115;
  --cui-white-rgb: 255, 255, 255;
  --cui-black-rgb: 0, 0, 21;
  --cui-body-color-rgb: 44, 56, 74;
  --cui-body-bg-rgb: 255, 255, 255;
  --cui-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto,
    "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --cui-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
  --cui-gradient: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.15),
    hsla(0, 0%, 100%, 0)
  );
  --cui-body-font-family: var(--cui-font-sans-serif);
  --cui-body-font-size: 1rem;
  --cui-body-font-weight: 700;
  --cui-body-line-height: 1.5;
  --cui-body-color: rgba(44, 56, 74, 0.95);
  --cui-body-bg: #fff;
  --cui-border-width: 1px;
  --cui-border-style: solid;
  --cui-border-color: #d8dbe0;
  --cui-border-color-translucent: rgba(0, 0, 21, 0.175);
  --cui-border-radius: 0.375rem;
  --cui-border-radius-sm: 0.25rem;
  --cui-border-radius-lg: 0.5rem;
  --cui-border-radius-xl: 1rem;
  --cui-border-radius-2xl: 2rem;
  --cui-border-radius-pill: 50rem;
  --cui-heading-color: unset;
  --cui-link-color: #321fdb;
  --cui-link-hover-color: #2819af;
  --cui-code-color: #d63384;
  --cui-highlight-bg: #fff3cd;
  --cui-breakpoint-xs: 0;
  --cui-breakpoint-sm: 576px;
  --cui-breakpoint-md: 768px;
  --cui-breakpoint-lg: 992px;
  --cui-breakpoint-xl: 1200px;
  --cui-breakpoint-xxl: 1400px;
  --cui-mobile-breakpoint: md;
}

body {
  color: #040054;
  color: var(--primary-color);
  font-family: Posey, sans-serif;
  font-family: var(--default-font);
  font-size: 16px;
  font-weight: 400;
  background: url("./Assets/img/bg.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;

  
}

p {
  line-height: 1.6em;
  margin-bottom: 1.5em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--default-font);
  margin-top: 0;
  font-weight: normal;
  /* letter-spacing: -0.02em; */
  color: var(--primary-color);
}

img {
  max-width: 100%;
}

a,
a:focus,
a:hover {
  text-decoration: none;
  transition: all 0.3s ease;
}

.main-area {
  max-width: 475px;
  margin: 0 auto;
}

.dashboard {
  max-width: 1200px;
  margin: 0 auto;
}

.btn-primary {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  line-height: 1.75;
  color: var(--secondary-color);
  background-color: var(--primary-color);
  border-radius: 20px;
  font-weight: normal;
  font-size: 1.3rem;
  padding: 10px 15px;
  min-width: 50px;
  box-shadow: none;
}

.btn-primary-mob {
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  line-height: 1.75;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.87);
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: rgb(251, 171, 52);
  border-radius: 20px;
  font-weight: 500;
  font-size: 1.3rem;
  padding: 5px;
  min-width: 50px;
  box-shadow: none;
  display: none;
  margin: -10px auto 4px;
  width: 95%;
}

.logo-area {
  text-align: center;
  margin-top: 20px;
}

.cont-title {
  margin: 8px 0px 0px;
  font-size: 23px;
  font-weight: normal;
  line-height: 1.6;
  color: var(--secondary-color);
}

.cont-subtitle {
  margin: 0px 0px 40px;
  font-size: 23px;
  font-weight: normal;
  line-height: 1.6;
  color: var(--secondary-color);
}

.button-area {
  margin: 12px 0;
}

.btn-comman {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  color: var(--secondary-color);
  line-height: 1.75;
  background-color: rgb(251, 171, 52);
  width: 100%;
  border-radius: 20px;
  font-weight: 500;
  font-size: 1.3rem;
  padding: 5px;
  min-width: 50px;
  box-shadow: none;
}

.btn-comman:disabled,
.btn-eat:disabled {
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12);
}

.btn-comman:disabled:hover {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
  cursor: not-allowed;
}

.btn-comman:hover {
  color: #fff;
  background-color: #0b5ed7;
}

.card {
  color: rgb(23, 33, 94);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.59) 6px 6px 20px 6px;
  border-radius: 20px;
  background: rgb(203 163 74);
  margin-bottom: 24px;
}

.grid-container {
  box-sizing: border-box;
  display: flex;
  flex-flow: wrap;
  width: 100%;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  margin-top: 8px;
}

.grid-inner-container {
  box-sizing: border-box;
  margin: 0px;
  flex-direction: row;
  flex-basis: 50%;
  -webkit-box-flex: 0;
  flex-grow: 0;
  max-width: 50%;
}

.grid-container p {
  margin: 0px;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.5;
  color: rgb(23, 33, 94);
}

.grid-container h5,
.row h5 {
  margin: 0px;
  font-size: 22px;
  font-weight: normal;
  line-height: 1.334;
}

.box-root {
  padding-top: 32px;
  padding-bottom: 16px;
}

.box-root-inner {
  padding-bottom: 16px;
  position: relative;
}

.box-root-inner input {
  font-size: 24px;
  font-weight: 500;
  padding: 9px 90px 12px 12px;
  text-align: right;
  border-radius: 0px;
  border: 5px solid #000;
  background: white;
  width: 100%;
  outline: none;
  line-height: normal;
}

.box-root-inner p {
  margin: 0px;
  line-height: 1.5;
  font-size: 26px;
  position: absolute;
  top: 9px;
  right: 20px;
  font-weight: 500;
  color: black;
}

.grid-box {
  box-sizing: border-box;
  display: flex;
  flex-flow: wrap;
  margin-top: -40px;
  width: calc(100% + 40px);
  margin-left: -40px;
  margin-bottom: 24px;
  justify-content: space-evenly;
  padding-right: 40px;
}

.grid-box-inner {
  box-sizing: border-box;
  margin: 0px;
  flex-direction: row;
  flex-basis: 8.33333%;
  -webkit-box-flex: 0;
  flex-grow: 0;
  max-width: 8.33333%;
  padding-left: 40px;
  padding-top: 40px;
}

.grid-box-btn {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  line-height: 1.75;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.87);
  background-color: rgb(251, 171, 52);
  width: 100%;
  border-radius: 20px;
  font-weight: 500;
  font-size: 1.3rem;
  padding: 5px;
  min-width: 50px;
  box-shadow: none;
}

.grid-box-btn span {
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  inset: 0px;
  border-radius: inherit;
}

.btn-comman.disabled {
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12);
  font-size: 1.5rem;
}

.btn-rebake {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  line-height: 1.75;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.87);
  background-color: rgb(251, 171, 52);
  width: 100%;
  border-radius: 20px;
  font-size: 1.3rem;
  padding: 5px;
  min-width: 50px;
  box-shadow: none;
}

.btn-rebake.disabled {
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12);
}

.btn-eat {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  vertical-align: middle;
  line-height: 1.75;
  color: var(--secondary-color);
  background-color: var(--primary-color);
  border-radius: 20px;
  font-weight: normal;
  font-size: 1.3rem;
  width: 100%;
  padding: 5px;
  min-width: 50px;
  box-shadow: none;
}

.card-referral {
  background-color: var(--primary-color);
}

.card-referral-title {
  margin: 0px 0px 0.35em;
  font-size: 24px;
  font-weight: normal;
  line-height: 1.334;
  color: #fff;
  text-align: center;
}

.copy-input {
  font-size: 10px;
  font-weight: normal;
  padding: 5px 12px;
  border-radius: 0px;
  border: 5px solid rgb(0, 0, 0);
  background: var(--text-color);
  width: 100%;
  outline: none;
}

.card-referral-desc {
  margin: 16px 0px 0px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.43;
  text-align: center;
  color: #fff;
  padding-left: 24px;
  padding-right: 24px;
}

.grid-containers {
  box-sizing: border-box;
  display: flex;
  flex-flow: wrap;
  width: calc(100% + 16px);
  margin-left: -16px;
  -webkit-box-pack: center;
  justify-content: center;
  margin-top: 8px;
}

.bg-green {
  background: #008000;
}

.text-yellow {
  color: #fdff00;
}

.table-head {
  background: #6b3711;
  padding: 10px;
  align-items: center;
  color: #fff;
}

.logo-img{
  height: 85px;
  width: 85px;
  border-radius: 20px;
}


.inrBox {
  background-repeat: no-repeat;
  background: radial-gradient(circle, transparent, #79420f);
  border: 1px solid #59471b;
  border-radius: 10px;
  border-style: ridge ridge groove groove;
  border-width: 2px;
  box-shadow: inset 0 0 5px 3px rgba(1, 1, 1, .3);
  display: block;
  height: 100%;
  overflow: hidden;
  padding: 20px 20px 15px;
  position: relative;
  text-align: left;
  background: #cba34a;
}

.inrBox p {
  color: #fff;
  font-size: 20px;
}

.inrBox h3 {
  font-weight: 700;
  position: relative;
  z-index: 1;
}

 .inrBox img {
  color: #726f00;
  height: 70px !important;
  width: 70px !important;
  z-index: 0;
}

p{
  margin: 0;
}

.inrBox h3{
  font-size: 20px;
  margin-bottom: 10px;
}


.navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .show>.nav-link{
  color: #3c1f0c;
}

.nav-link{
  font-size: 20px;
}

.page-margin{
  margin-left: 30px;
  margin-right: 30px;
}

.table{
  background: #cba34a;
  color: var(--primary-color);
}

@media (max-width: 899.95px) {
  .btn-primary {
    display: flex;
  }

  .btn-primary-mob {
    display: flex;
  }
}

.desk-none{
  display: none;
}

.mob-none{
  display: flex;
}

.footer {
  background-color: #f8f9fa; 
  padding: 10px 0; 
  margin-top: 50px;
  /* position: fixed;  */
  bottom: 0;
  width: 100%; /* Full width */
  text-align: center; /* Center text */
}



@media (max-width: 599.95px) {
  .main-area {
    max-width: 100%;
    padding: 0 15px;
  }

  .desk-none{
    display: flex;
  }

  .mob-none{
    display: none;
  }

  .navbar-nav{
      background: #cba34a;
      padding: 10px;
  }
}